import on from './utils/on.js';
import ready from './utils/ready.js';

import parseSrcset from 'parse-srcset';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default';

const IMAGESET_SELECTOR    = "img[data-enlarge='true']";
const PHOTOSWIPE_ELEMENT   = document.querySelector('.pswp');

let currentGalleryItems = null;

const PHOTOSWIPE_OPTIONS   = {
  index:     0,
  history:   false,
  shareEl:   false,
  bgOpacity: 0.95,

  getThumbBoundsFn(index) {
    const thumbnail = currentGalleryItems[index].sourceImage;
    const pos       = thumbnail.getBoundingClientRect();

    return {
      x: pos.left,
      y: pos.top + window.pageYOffset,
      w: pos.width
    };
  }

};

let currentGallery = null;

function init() {

  document.querySelectorAll(IMAGESET_SELECTOR).forEach((item) => item.classList.add("is-zoomable"));

  on(document.body, 'click', IMAGESET_SELECTOR, function(e) {

    const options = Object.assign({}, PHOTOSWIPE_OPTIONS);
    const items   = getGalleryItems();

    for (let i = 0, l = items.length; i < l; i++){
      if(items[i].sourceImage == this) {
        options.index = i;
        break;
      }
    }

    currentGalleryItems = items;

    console.log("gal", currentGalleryItems);

    const gallery = new PhotoSwipe(PHOTOSWIPE_ELEMENT, PhotoSwipeUI_Default, items, options);
    gallery.init();
  });
}

function getGalleryItems() {
  const images = document.querySelectorAll(IMAGESET_SELECTOR);
  const items = [];

  console.log("im", images);

  for (let img of images) {

    if (img.hasAttribute('srcset')) {
      const srcset = parseSrcset(img.getAttribute("srcset"));

      let largest = null;

      for (let i = 0, l = srcset.length; i < l; i++) {
        if(!largest || srcset[i].w > largest.w) {
          largest = srcset[i];
        }
      }

      items.push({
        src:  largest.url,
        w:    largest.w,
        h:    largest.w * parseInt(img.getAttribute("height"), 10) / parseInt(img.getAttribute("width"), 10),
        msrc: img.currentSrc,
        sourceImage: img,
      });
    } else {
      items.push({
        src:  img.src,
        w:    parseInt(img.getAttribute("width"), 10),
        h:    parseInt(img.getAttribute("height"), 10),
        msrc: img.currentSrc,
        sourceImage: img,
      });
    }
  }

  return items;
}

ready(() => init());
