import "../scss/index.scss";
import ready from './utils/ready.js';
import './lightbox.js';

// Accordion

ready(() => {
    const accordion = document.querySelector('.js-accordion');

    if (accordion) {
        import(/* webpackChunkName: "accordion" */ 'badger-accordion').then(({ default: Accordion }) => {

            const extraOptions = {};

            const hash = window.location.hash.replace(/^#/, '');

            if (hash !== '') {
                const headers = accordion.getElementsByTagName('dt');
                let openHeader = false;

                for (let i = 0, l = headers.length; i < l; i++) {
                    if(headers[i].getAttribute('id') === hash) {
                        extraOptions.openHeadersOnLoad = [i];
                        openHeader = i;
                        break;
                    }
                }

                if (openHeader !== false) {
                    setTimeout(() => {
                        headers[openHeader].scrollIntoView(true);
                    }, 300);
                }
            }

            const badger = new Accordion(accordion, {
                headerClass: '.js-accordion-header',
                panelClass: '.js-accordion-panel',
                panelInnerClass: '.js-accordion-panel-inner',
                hiddenClass: 'is-hidden',
                initalisedClass: 'accordion--initalised',
                headerDataAttr: 'data-accordion-header-id',
                ...extraOptions,
            });

        });
    }
});
